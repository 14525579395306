<template>
    <div>
        <h4 class="bold text-uppercase">{{ $route.name }}</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    @click="handleShowPanel('list')"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    >Tạo tiến trình</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('logs')"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    >Nhật ký Order</a
                >
            </li>
            <!-- <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('logs-sv3')"
                    id="sv3-logs-tab"
                    data-toggle="tab"
                    href="#sv3-logs"
                    role="tab"
                    aria-controls="sv3-logs"
                    aria-selected="false"
                    >Nhật ký Order SV3</a
                >
            </li> -->
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">
                                    Link hoặc ID
                                    {{ type == "follow" ? "trang cá nhân" : "bài viết" }}:
                                </h6>
                            </div>
                            <div class="col-md col-12">
                                <input
                                    v-model="object_id"
                                    type="text"
                                    class="form-control input light"
                                    placeholder="Nhập link hoặc ID cần tăng"
                                />
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">
                                    Loại Seeding cần tăng:
                                </h6>
                            </div>
                            <div class="col-md col-12 text-right">
                                <select v-model="type" class="custom-select select-light">
                                    <option v-if="type == 'like'" value="like">Like bài viết Instagram</option>
                                    <option v-if="type == 'comment'" value="comment"
                                        >Bình luận bài viết Instagram</option
                                    >
                                    <option v-if="type == 'follow'" value="follow"
                                        >Tăng Follow - Theo dõi trang cá nhân Instagram</option
                                    >
                                </select>
                            </div>
                        </div>
                        <!-- <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Chế độ bảo hành:</h6>
                            </div>
                            <div class="col-md col-12 text-left">
                                <select
                                    v-model="is_warranty"
                                    class="form-control custom-select select-light"
                                    required="required"
                                >
                                    <option :value="false">Không bảo hành (Bảo hành nếu tụt quá 70%)</option>
                                    <option :value="true">Bảo hành (Bảo hành nếu tụt quá 10%)</option>
                                </select>
                                <div v-show="is_warranty" class="row mt-4">
                                    <div class="form-group col-md-6">
                                        <label>Chọn ngày bắt đầu</label>
                                        <datetime
                                            class="theme-green"
                                            type="datetime"
                                            v-model="time_start"
                                            format="yyyy-MM-dd HH:mm"
                                            input-class="form-control"
                                            :min-datetime="minDatetime"
                                        ></datetime>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Chọn ngày kết thúc</label>
                                        <datetime
                                            class="theme-green"
                                            type="datetime"
                                            v-model="time_expired"
                                            format="yyyy-MM-dd HH:mm"
                                            input-class="form-control"
                                            :min-datetime="time_start"
                                            :max-datetime="increaseDateByNumber(time_start, date_warranty)"
                                        ></datetime>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div v-if="type != 'comment' && type != 'share_group'" class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Số lượng cần tăng:</h6>
                            </div>
                            <div class="col-md col-12">
                                <input v-model="quantity" type="number" class="form-control input-light" />
                                <div class="card card-white mt-2">
                                    <div class="card-body p-2">
                                        <h6 class="font-13 text-danger font-bold">
                                            Lưu ý: hệ thống hoạt động theo hình thức order tức là bạn order số lượng bao
                                            nhiêu thì hệ thống sẽ tự động lấy trong cơ sở dữ liệu ra số lượng người
                                            tương ứng để like, follow,... cho bạn.
                                        </h6>
                                        <h6 class="font-13 text-danger font-bold">
                                            Nên nếu nick của họ bị khóa (checkpoint) sau khi buff sẽ gây ra hiện tưởng
                                            tụt trong lúc buff là bình thường.
                                        </h6>
                                        <h6 class="font-13 text-danger font-bold mb-0">
                                            Do đó, vui lòng buff dư thêm 30 - 50% trên tổng số lượng để tránh tụt hoặc
                                            chọn gói bảo hành để được hoàn tiền nếu tụt.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Server:</h6>
                            </div>
                            <div class="col-md col-12">
                                <div class="card card-white">
                                    <div class="card-body p-2 ">
                                        <label for="corona" class="mb-1 d-flex align-items-center cursor-pointer"
                                            ><input
                                                type="radio"
                                                name="server"
                                                value="corona"
                                                id="corona"
                                                v-model="server"
                                                class="cursor-pointer"
                                            /><span class="ml-2"
                                                >Server <span class="text-danger font-bold mr-2">VIP</span>
                                                <span class="btn btn-info py-0 px-1">
                                                    {{ priceDefaultCorona }} mCoin</span
                                                >
                                                (chất lượng cao: người việt, người thật - click bằng tay, tốc độ trung
                                                bình, tỉ lệ tụt thấp)</span
                                            ></label
                                        >
                                        <!-- <label
                                            v-if="type !== 'comment' && is_warranty !== true"
                                            for="sv3"
                                            class="mb-1 d-flex align-items-center cursor-pointer"
                                            ><input
                                                type="radio"
                                                name="server"
                                                id="sv3"
                                                value="sv3"
                                                v-model="server"
                                                class="cursor-pointer"
                                            /><span class="ml-2"
                                                >Server <span class="text-danger font-bold mr-2">SV3</span>
                                                <span class="btn btn-info py-0 px-1"> {{ priceDefaultSv3 }} mCoin</span>
                                                (clone nuôi, tốc độ nhanh, không bảo hành)</span
                                            ></label
                                        > -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group" v-if="speedArray.length > 0">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Tốc độ:</h6>
                            </div>
                            <InstagramSpeed
                                :server="server"
                                :speeds="speedArray"
                                @updateChosenSpeed="updateChosenSpeed"
                            />
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">
                                    Giá tiền mỗi tương tác:
                                </h6>
                                <h6 class="text-danger bold font-13">
                                    Giá thấp nhất: {{ priceDefault }}
                                    {{ currency }}
                                </h6>
                            </div>
                            <div class="col-md col-12">
                                <input
                                    type="number"
                                    placeholder="Giá tiền bạn muốn bid cho mỗi tương tác"
                                    v-model="price"
                                    class="form-control input-light"
                                />
                                <div class="card card-white mt-2">
                                    <div class="card-body p-2">
                                        <h6 class="font-13 mb-0 text-justify">
                                            Mẹo nhỏ: Hệ thống ưu tiên chạy các job giá cao trước nên nếu bạn cần gấp bạn
                                            có thể set giá job của mình cao hơn 1 vài đồng để chạy nhanh nhất có thể
                                            nhé.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-show="type === 'comment' || type === 'review'" class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">
                                    Giá tiền mỗi tương tác:
                                </h6>
                            </div>
                            <div class="col-md col-12">
                                <div class="card card-white">
                                    <div class="card-body">
                                        <h6 class="text-danger font-bold font-14">
                                            Nghiêm cấm bình luận những nội có cử chỉ, lời nói thô bạo, khiêu khích, trêu
                                            ghẹo, xúc phạm nhân phẩm, danh dự của Cá nhân hoặc Tổ chức.
                                        </h6>
                                        <h6 class="text-danger font-bold font-14">
                                            Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và
                                            phải chịu hoàn toàn trách nhiệm trước pháp luật.
                                        </h6>
                                    </div>
                                </div>
                                <div>
                                    <label class="mr-2 mt-3"
                                        >Nhập nội dung bình luận (<label class="font-bold"
                                            >Mỗi dòng tương đương với 1 bình luận</label
                                        >):</label
                                    >
                                    <span class="badge badge-primary text-white py-1">Số lượng: {{ quantity }}</span>
                                    <textarea
                                        class="form-control input-light"
                                        rows="10"
                                        v-model="list_messages"
                                        placeholder="Nhập nội dung seeding, mỗi dòng 1 nội dung được tính tiền là 1 bình luận"
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Ghi chú:</h6>
                            </div>
                            <div class="col-md col-12 text-right">
                                <textarea
                                    class="form-control input-light"
                                    rows="2"
                                    v-model="notes"
                                    placeholder="Nhập nội dung ghi chú về tiến trình của bạn"
                                ></textarea>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <div class="card card-info">
                                    <div class="card-body">
                                        <h5 class="font-bold">
                                            Tổng:
                                            <span class="bold"
                                                ><number
                                                    ref="number1"
                                                    :from="0"
                                                    :to="totalPrice"
                                                    :format="theFormat"
                                                    :duration="1"
                                                    :delay="0"
                                                    easing="Power1.easeOut"
                                                />
                                                {{ currency }}</span
                                            >
                                        </h5>
                                        <h6 class="mb-0">
                                            Bạn sẽ buff
                                            <span class="bold">{{ quantity }} {{ type }}</span>
                                            với giá
                                            <span class="green bold">{{ priceSingle | formatNumber }}</span>
                                            {{ currency }} / {{ type }}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <button
                                    @click="order()"
                                    :class="['btn btn-block btn-dark-blue btn-lg btn-rounded bold']"
                                    type="submit"
                                >
                                    Tạo tiến trình
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card card-red">
                            <div class="card-body color-info">
                                <p class="font-bold">Chú ý:</p>
                                <p class="font-bold">
                                    - Ngiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy...
                                    Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và phải
                                    chịu hoàn toàn trách nhiệm trước pháp luật.
                                </p>
                                <p>
                                    - Hệ thống sử dụng 99% tài khoản người VN, Instagram thật để tương tác like, sub,
                                    comment....
                                </p>
                                <p>
                                    - Thời gian làm việc (tăng seeding) và bảo hành tính từ ngày bắt đầu cho đến ngày
                                    kết thúc job, tối đa là 1 tuần
                                </p>
                                <p>
                                    - Hết thời gian của job đã order nếu không đủ số lượng hệ thống sẽ tự động hoàn lại
                                    số tiền seeding chưa tăng cho bạn
                                </p>
                                <p>
                                    - Vui lòng lấy đúng id và check kỹ job tránh tạo nhầm, tính năng đang trong giai
                                    đoạn thử nghiệm nên sẽ không hoàn tiền nếu bạn tạo nhầm
                                </p>
                                <p class="font-bold">
                                    - Nhập id lỗi hoặc trong thời gian chạy die id thì hệ thống không hoàn lại tiền.
                                </p>
                                <p v-if="type === 'follow'" class="font-bold">
                                    - Chỉ nhận link hoặc id trang cá nhân.
                                </p>
                                <p v-else class="font-bold">
                                    - Chỉ nhận link hoặc id trang bài viết.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <InstagramLogs :panel="panel" :type="type" />
            </div>
            <!-- <div class="tab-pane fade" id="sv3-logs" role="tabpanel" aria-labelledby="sv3-logs-tab">
                <InstagramSv3Logs :panel="panel" :type="type" :typeSv3="packageNameSv3" />
            </div> -->
        </div>
    </div>
</template>
<script>
import { catchError } from "@/helpers"
import { orderSeedingJob } from "@/api/services/instagram"
import { createOrder } from "@/api/services/intagram-sv3"
import InstagramLogs from "./instagram-logs"
import InstagramSpeed from "./instagram-speed.vue"
// import InstagramSv3Logs from "./instagram-sv3-logs.vue"

export default {
    name: "instagram-order",
    components: { InstagramLogs, InstagramSpeed },
    data() {
        return {
            object_id: "",
            type: "",
            is_warranty: false,
            time_start: new Date().toISOString(),
            minDatetime: new Date().toISOString(),
            time_expired: "",
            date_warranty: 7,
            price: 0,
            is_filter: false,
            panel: "order",
            quantity: 100,
            notes: "",
            list_messages: "",
            server: "corona",
            speed: ""
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        prices() {
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 77)
            return tool.prices
        },
        priceSingle() {
            let price = parseInt(this.price)
            return Math.ceil(price)
        },
        totalPrice() {
            return this.priceSingle * this.quantity
        },
        router() {
            return this.$route.path
        },
        packageName() {
            if (this.server === "sv3") return "instagram_" + this.type + "_sv3"
            return "instagram_" + (this.is_warranty ? this.type + "_warranty" : this.type)
        },
        packageNameSv3() {
            return "instagram_" + this.type + "_sv3"
        },
        speedArray() {
            const price = this.prices?.find(obj => obj.package_name == this.packageName)
            return price ? price.speed : []
        },
        priceDefaultSv3() {
            const packageName = "instagram_" + this.type + "_sv3"
            return this.getDefaultPrice(packageName)
        },
        priceDefaultCorona() {
            const packageName = "instagram_" + this.type + (this.is_warranty ? "_warranty" : "")
            return this.getDefaultPrice(packageName)
        },
        priceDefault() {
            const { packageName, speedArray, prices, speed } = this
            if (speedArray.length > 0) {
                const selectedSpeed = speedArray.find(item => item.speed === speed)
                return selectedSpeed?.price || speedArray[0].price
            }
            const price = prices?.find(obj => obj.package_name === packageName)
            return price?.price || 0
        }
    },
    watch: {
        router(value) {
            if (value.includes("follow")) {
                this.quantity = 100
                this.type = "follow"
            } else if (value.includes("comment")) {
                this.quantity = this.list_messages ? this.list_messages.match(/.+/g).length : 0
                this.type = "comment"
            } else {
                this.quantity = 100
                this.type = "like"
            }
        },
        list_messages(value) {
            this.quantity = value ? value.match(/.+/g).length : 0
        },
        object_id(value) {
            if (value && value.includes("instagram.com")) {
                this.object_id = this.getPostId(value)
            }
        },
        type() {},
        is_warranty(value) {
            if (value) this.server = "corona"
        },
        priceDefault(value) {
            this.price = value
        }
    },
    async created() {
        if (this.$route.path.includes("follow")) {
            this.quantity = 100
            this.type = "follow"
        } else if (this.$route.path.includes("comment")) {
            this.quantity = this.list_messages ? this.list_messages.match(/.+/g).length : 0
            this.type = "comment"
        } else {
            this.quantity = 100
            this.type = "like"
        }
        this.time_expired = this.increaseDateByNumber(this.time_start, this.date_warranty)
    },
    methods: {
        updateChosenSpeed: function(value) {
            this.speed = value
        },
        order: async function() {
            if (!this.object_id) {
                this.$toastr.error("Vui lòng nhập ID cần tăng")
                return
            }
            if (this.price >= 2 * this.priceDefault) {
                this.$swal(
                    "Thông báo",
                    "Giá bạn đang chọn cao hơn giá bình thường nhiều lần, vui lòng chỉnh lại giá!",
                    "error"
                )
                return
            }

            if (this.type === "comment" && this.list_messages.length < 1) {
                this.$toastr.error("Vui lòng nhập nội dung cần comment")
                return
            }
            if (this.type === "comment") {
                let checkBackList = this.list_messages
                    .toLowerCase()
                    .match(
                        /dm|đm|đ m|d m|địt mẹ|dit me|lol|lừađảo|conchó|trảtiền|mấtdạy|lừa đảo|con chó|trả tiền|mất dạy|lua dao|con cho|tra tien|mat day/g
                    )
                if (this.list_messages.length > 1 && checkBackList && checkBackList.length > 0) {
                    this.$swal(
                        "Lỗi",
                        `${this.type === "comment" ? "Bình luận" : "Review"} của bạn có chứa nội dung [${
                            checkBackList[0]
                        }] vi phạm tiêu chuẩn cộng đồng, hãy chỉnh sửa lại nội dung cần bình luận!`,
                        "error"
                    )
                    return
                }
            }
            if (this.price < this.priceDefault) {
                this.$swal("Lỗi", "Giá cài đặt phải lớn hơn giá thấp nhất", "error")
                return
            }
            this.$swal({
                title: "Xác nhận",
                text: `Bạn có chắc chắn muốn Order ${this.quantity} ${this.type.toUpperCase()} với giá: ${this.price} ${
                    this.currency
                }/1 ${this.type.toUpperCase()} ?`,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Đồng Ý",
                cancelButtonText: "Không",
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.orderConfirm()
                } else if (result.dismiss === this.$swal.cancel) {
                    this.$swal("Thông tin", "Bạn đã hủy bỏ Order hiện tại!", "info")
                }
            })
        },
        orderConfirm: async function() {
            let postData = {
                link: "https://www.instagram.com/p/" + this.object_id,
                type: this.type,
                object_id: this.object_id,
                quantity: this.quantity,
                prices: this.totalPrice,
                time_expired: this.time_expired,
                provider: "instagram",
                is_warranty: this.is_warranty,
                notes: this.notes
            }

            if (this.type === "comment") {
                postData.list_messages = this.list_messages.match(/.+/g)
            }
            if (this.type === "follow") {
                postData.link = "https://www.instagram.com/" + this.object_id
            }
            if (this.server === "sv3") {
                postData.is_warranty = false
                postData.speed = this.speed
                postData.type = this.packageName
            }
            if (this.server === "corona") {
                postData.speed = this.speed
                postData.type = this.type
            }
            let data
            if (this.server == "sv3") {
                data = await createOrder(postData)
            } else {
                data = await orderSeedingJob(postData)
            }
            if (data.status === 200 && data.success) {
                this.$swal(
                    "Thành công",
                    "Tạo tiến trình seeding thành công, hệ thống sẽ tự động tăng theo thời gian bạn đã chọn</br></br>Nếu không đủ lượt seeding hệ thống sẽ tự động hoàn lại số tiền chưa chạy",
                    "success"
                )
            } else {
                if (data.message) {
                    this.$swal("Lỗi", data.message, "error")
                } else {
                    this.$swal("Lỗi", catchError(data), "error")
                }
            }
            // this.$store.dispatch("USER_GET_INFO")
        },
        getDefaultPrice(package_name) {
            const serverPackage = this.prices?.find(obj => obj.package_name === package_name)
            if (serverPackage.speed?.length > 0) {
                const defaultSpeed = serverPackage.speed.find(item => !!item.default)
                return defaultSpeed?.price || serverPackage.speed[0].price
            }
            return serverPackage.price
        },
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
        },
        increaseDateByNumber(date, number = 0) {
            const dateInit = new Date(date).valueOf()
            const milisecondIncrease = number * 24 * 60 * 60 * 1000
            return new Date(dateInit + milisecondIncrease).toISOString()
        },

        handleShowPanel: async function(panel) {
            this.panel = panel
        },
        getPostId: function(url) {
            let result = null
            this.$toastr.success("Hệ thống đang tự động get id cho link instagram bạn nhập vào!")
            if (url.indexOf("instagram.com") < 0) {
                result = url
            } else {
                this.$toastr.success("Đã tự động get id cho bạn")
                let postId = ""
                if (this.type === "follow") {
                    postId = url.match(/(.*).com\/(.*)?/)
                } else postId = url.match(/(.*)\/p\/(.*)?/)
                if (postId) {
                    result = postId[2].split("?")[0].replace("/", "")
                }
            }
            return result
        }
    }
}
</script>
